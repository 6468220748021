import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
 
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
	{
		// 首页面
		path: '/',
		component: () => import('../views/Home.vue'),
	},
	{
		path: '/Information',
		component: () => import('../views/Information.vue'),
	},
	{
		path: '/resources',
		component: () => import('../views/resources.vue'),
	},
	{
		path: '/car',
		component: () => import('../views/car.vue'),
	},
	{
		path: '/our',
		component: () => import('../views/our.vue'),
	},
	{
		path: '/details',
		component: () => import('../views/details.vue'),
	},
]
const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})
export default router